import React, {useCallback, useEffect, useMemo, useState, Fragment} from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Airtable from "airtable";
import { Record } from "./components/Record";
import "./App.css";
import {App as AntApp, ConfigProvider, Button, Layout, Typography, Row, Col, Space, Descriptions, Card, Result, Spin, ResultProps} from "antd";

const { Content } = Layout;

const base = new Airtable({
  apiKey:
    "patdC1GdrVeUMopZ4.664bd4921c59561d2ccdd407518a5393281247f90f2f53aaad81d159601ac388",
}).base("appbwdgKDzRYXhteL");
const offers = base<{ id: string; 'Agreed Terms & Conditions': boolean; 'Agreed Privacy Policy': boolean, Name: string, 'State of organization': string, Token: string, 'Signed on': string, 'Contract start date': string, 'Contract end date': string, 'Number of free months': number, 'Integration fee dollar amount': number, 'Criteria to waive the integration fee'?: string[], 'Other terms'?: string[], 'First payment date'?: string }>(
  "Offers"
);

const HIDDEN_FIELDS = ["id", 'Vendor', 'Address', "Token", "Name", "Email address", "Terms & Conditions", 'State of organization', 'Signed on', 'Contract start date', 'Number of free months', 'Integration fee dollar amount', 'Criteria to waive the integration fee', 'Monthly fee', "Created By", "Agreed Terms & Conditions", "Created", "Last Modified", "Contract end date", "Other terms", "Privacy Policy Agreement", "Agreed Privacy Policy", "First payment date"];

function App() {
  const [fields, setFields] = useState<{ [key: string]: any }>();
  const [result, setResult] = useState<ResultProps>();
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const handleFinish = useCallback(async () => {
      const token = await getAccessTokenSilently();
      const signedOn = new Date().toLocaleDateString('en-US');
      offers
          .update(fields?.["id"], {
              'Agreed Terms & Conditions': true,
              'Agreed Privacy Policy': true,
              Name: user?.name,
              Token: token,
              'Signed on': signedOn,
          })
          .then(() => {
              setResult({
                  title: "Contract is signed",
                  status: "success",
                  subTitle: "Our team will reach you shortly"
              });
              setFields({ ...fields, 'Agreed Terms & Conditions': true, 'Signed on': signedOn });
              window.scrollTo({
                    top: 0,
              })
          });
  }, [fields]);

  useEffect(() => {
    if (user?.email) {
      offers
        .select({
          maxRecords: 1,
          view: "Grid view",
          filterByFormula: `{Email address} = '${user.email}'`,
        })
        .eachPage(function page(offers) {
          const offer = offers[0];

          if (!offer) {
            setResult({
                title: "No offer found",
                status: "warning",
                subTitle: "Please contact our team for assistance"
            });
            return;
          }
            if (offer.fields['Agreed Terms & Conditions']) {
                setResult({
                    title: "Contract is signed",
                    status: "success",
                    subTitle: "Our team will reach you shortly"
                });
            }
          setFields({ ...offer.fields, id: offer.id });
        });
    }
  }, [user?.email]);
  const customFields = useMemo(() => {
      if (fields) {
          return Object.keys(fields).filter(key => !HIDDEN_FIELDS.includes(key))
      }

        return [];
  }, [fields]);

  return (
      <ConfigProvider theme={{
          components: {
              Descriptions: {
                  itemPaddingBottom: 2,
                  titleMarginBottom: 8
              }
          }
      }}>
          <AntApp>
              <Layout style={{ minHeight: '100vh' }}>
                  <Content style={{ padding: 24, alignItems: 'stretch' }}>
                      <Row>
                          <Col span={24} md={{ offset: 6, span: 12 }}>
                              {!fields && !result ? <Spin size="large" fullscreen /> : <Space direction="vertical" style={{
                                  width: '100%'
                              }}>
                                  <Card>
                                      <Typography.Title style={{ textAlign: 'center' }} level={2}>Kimelo by Cheetah Technologies Order form</Typography.Title>
                                      <Typography.Paragraph>
                                          Capitalized terms used but not defined in this Order Form have the meaning given to those terms in the Kimelo by Cheetah Technologies Terms & Conditions entered into by Customer in connection with the Order Form.
                                      </Typography.Paragraph>
                                      {result && <Result {...result} />}
                                      {fields && <Space direction="vertical" size="large">
                                          <Space direction="vertical" size="large">
                                              <Descriptions title="DATES" size="small" layout="vertical" bordered items={[
                                                  ...(fields['Signed on'] ? [{
                                                      label: 'Signed on',
                                                      children: <Typography.Text strong>{new Date(fields['Signed on']).toLocaleDateString('en-US')}</Typography.Text>,
                                                  }] : []),
                                                    {
                                                        label: 'Contract start date',
                                                        children: <Typography.Text strong>{new Date(fields['Contract start date']).toLocaleDateString('en-US')}</Typography.Text>,
                                                  },
                                                  ...(fields['Contract end date'] ? [{
                                                      label: 'Contract end date',
                                                      children: <Typography.Text strong>{new Date(fields['Contract end date']).toLocaleDateString('en-US')}</Typography.Text>,
                                                  }] : []),
                                              ]} />
                                              <Descriptions title="CUSTOMER" size="small" bordered column={3} layout="vertical" items={[
                                                  {
                                                    label: 'Company',
                                                    children: <Typography.Text strong>{fields['Vendor']}</Typography.Text>,
                                                  },
                                                  {
                                                        label: 'Name',
                                                        children: <Typography.Text strong>{user?.name}</Typography.Text>,
                                                    },
                                                    {
                                                        label: 'State of organization',
                                                        children: <Typography.Text strong>{fields['State of organization']}</Typography.Text>,
                                                  },
                                              ]} />
                                              <Descriptions title="DESCRIPTION OF SERVICES" items={[
                                                  {
                                                        children: <Typography.Text>The eCommerce Software (including AI Order Tool, mobile and Web Ordering Apps and Routing and Driver App) (the “Services)”.</Typography.Text>,
                                                  }
                                              ]} />
                                              <Space direction="vertical">
                                                  <Descriptions labelStyle={{
                                                      width: '50%'
                                                  }} title="FEES" size="small" column={1} bordered items={[
                                                      ...(fields['Number of free months'] ? [{
                                                          label: 'Free trial period (months)',
                                                          children: <Typography.Text strong>{fields['Number of free months']}</Typography.Text>,
                                                      }] : []),
                                                      {
                                                          label: 'Integration fee',
                                                          children: <Typography.Text strong>${fields['Integration fee dollar amount']}</Typography.Text>,
                                                      },
                                                      ...(fields['Monthly fee'] ? [{
                                                          label: 'Monthly fee',
                                                          children: <Space direction="horizontal">
                                                              <Typography.Text strong>${fields['Monthly fee']}</Typography.Text> <Typography.Text strong>First payment date: {new Date(fields['First payment date']).toLocaleDateString('en-US')}</Typography.Text>
                                                          </Space>,
                                                      }] : []),
                                                  ]} />
                                                  <Typography.Text strong>Provider will invoice Customer either (a) monthly or (b) once annually at the beginning of the subscription period for a 10% annual discount</Typography.Text>
                                                  <Descriptions labelStyle={{
                                                      width: '50%'
                                                  }} size="small" items={[...(fields['Other terms'] ? [{
                                                      label: 'Other terms',
                                                      children: <Typography.Text strong>{fields['Other terms']?.map((term: string) => (
                                                          <Fragment key={term}>
                                                              <Typography.Text>{term}</Typography.Text>
                                                              <br />
                                                          </Fragment>
                                                      ))}</Typography.Text>,
                                                  }] : [])]} column={1} bordered />
                                              </Space>
                                              <Typography.Text>Note that client revenue is paid net of any applicable, credit card payment transaction processing fees and ACH transaction fees at the then current market rate for such fees.</Typography.Text>
                                              {result && (
                                                  <Descriptions title="SERVICE LEVELS" items={[
                                                      {
                                                          children: <Typography.Text>Provider will maintain an uptime of 99%, which shall not include any Service Suspensions or Scheduled Downtime, over the period of any month (“Service Level”).</Typography.Text>,
                                                      }
                                                  ]} />
                                              )}
                                              {customFields.length ? (
                                                  <Descriptions column={2} layout="vertical" items={customFields.map(key => ({
                                                      label: key,
                                                      children: <Typography.Text strong>{fields[key]}</Typography.Text>,
                                                  }))} />
                                              ) : null}
                                          </Space>
                                          <Record fields={fields} isSubmitted={Boolean(result)} onFinish={handleFinish} />
                                      </Space>}
                                  </Card>
                                  <Card>
                                      <Space direction="vertical" size="large">
                                          <Space direction="vertical" size="small">
                                              <Typography.Text>Email support: <Typography.Link href="mailto:support@kimelo.com">support@kimelo.com</Typography.Link></Typography.Text>
                                              <Typography.Text>Call support: <Typography.Link href="tel:+1-888-606-3886">1 (888) 606-3886</Typography.Link></Typography.Text>
                                          </Space>
                                          <Button color="danger" onClick={() => logout()}>
                                              Logout
                                          </Button>
                                      </Space>
                                  </Card>
                              </Space>}
                          </Col>
                      </Row>
                  </Content>
              </Layout>
          </AntApp>
      </ConfigProvider>
  );
}

export default withAuthenticationRequired(App);
